<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row>
        <b-col class="text-right">
          <b-button
            size="sm"
            variant="primary"
            class="mb-1"
            @click="shoppersExport()"
          >
            {{$t('Shoppers Export')}}
            <!-- <feather-icon icon="EyeIcon" /> -->
          </b-button>
        </b-col>
      </b-row>
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="shoppers && Object.keys(shoppers.data).length > 0">
        <template v-for="(data, index) in shoppers.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <b-col md="9" cols="8">
                  <b-card-body>
                    <b-card-title>{{data.name}}</b-card-title>
                    <b-row class="m-0">
                      #{{data.id}} -
                      {{data.mobile}} -
                      {{data.email}}
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="3" cols="4" align-self="center" class="text-center">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    :to="`/shoppers/${data.id}`"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="shoppers" :limit="7" :data="shoppers" @pagination-change-page="getShoppers"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'Shoppers',
  data() {
    return {
      shoppers: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getShoppers();
  },
  methods: {
    getShoppers(page = 1){
      this.isLoading = true
      let data = {
          params: {
              page: page,
          }
      }
      useJwt.get('/shoppers/get-all',data)
      .then((response) => {
        // console.log(response.data)
        this.shoppers = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    shoppersExport() {
      useJwt.get('/shoppers/export')
      .then((response) => {
        console.log(response.data)
        return response.data;
      })
      .catch(response => {
        // console.log(response);
      });
    }
  }
}
</script>
